/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$main-font-family: 'Roboto', sans-serif;
$heading-font-family: 'Comfortaa', sans-serif;
$main-color: #00AFEF;
$optional-color: #e60027;
$white-color: #ffffff;
$black-color: #111111;
$paragraph-color: #606060;
$font-size: 16px;
$transition: .1s;

body {
    padding: 0;
    margin: 0;
    font: {
        family: $main-font-family;
        size: $font-size;
    };
}
a {
    text-decoration: none;
    transition: $transition;
    color: $black-color;

    &:hover, &:focus {
        color: $main-color;
        text-decoration: none;
    }
}
button {
    outline: 0 !important;
}
p {
    margin-bottom: 15px;
    line-height: 1.8;
    color: $paragraph-color;
    font-weight: 400;
    font: {
        family: $main-font-family;
        size: $font-size;
    };
    &:last-child {
        margin-bottom: 0;
    }
}
i {
    line-height: normal;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 100%;
    height: auto;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.ptb-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    color: $black-color;
    font-family: $heading-font-family;
    font-weight: 500;

    &:last-child {
        margin-bottom: 0;
    }
}

/*================================================
Default CSS
=================================================*/
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    background-color: $main-color;
    color: $white-color;
    transition: $transition;
    border-radius: 5px;
    opacity: 1;
    font: {
        weight: 400;
        size: $font-size;
    }
    padding: {
        left: 45px;
        right: 45px;
        top: 12px;
        bottom: 12px;
    }
    span {
        position: absolute;
        display: inline-block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $black-color;
        color: $white-color;
        transition: width 0.5s ease-in-out,
        height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
        opacity: 1;
    }
    &:hover {
        color: $white-color !important;
        span {
            width: 225%;
            height: 562.5px;
        }
    }
    i {
        font-size: 12px;
        position: relative;
        top: -1px;
        margin-left: 10px;
    }
}

/*================================================
Section Title CSS
=================================================*/
.section-title {
    text-align: center;
    margin: {
        bottom: 45px;
    };
    span {
        color: $main-color;
    }
    h2 {
        font-size: 36px;
        font-weight: 500;
        margin: {
            top: 10px;
            bottom: 14px;
        };
    }
    p {
        max-width: 570px;
        margin: auto;
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.main-responsive-nav {
    display: none;
}
.main-navbar {
    padding-top: 0;

    .container-fluid {
        max-width: 1470px;
    }
    .navbar {
        transition: $transition;
        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        };
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-brand {
            h1 {
                color: $white-color;
                margin-bottom: 0;
            }
            img {
                max-width: 150px !important;
            }
        }
        .navbar-nav {
            margin-left: auto;

            .nav-item {
                position: relative;
                margin: {
                    left: 15px;
                    right: 15px;
                };
                a {
                    transition: $transition;
                    color: $black-color;
                    font: {
                        size: $font-size;
                        weight: 500;
                        family: $heading-font-family;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                        top: 25px;
                        bottom: 25px;
                    };
                    &:hover, &:focus, &.active {
                        color: $main-color;
                    }
                }
                .dropdown-toggle {
                    padding-right: 17px;

                    &::after {
                        display: none;
                    }
                    &::before {
                        content: "\e9e2";
                        position: absolute;
                        right: -4px;
                        top: 27px;
                        font-size: 20px;
                        font-family: "boxicons";
                        line-height: 1;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:hover, &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    border: none;
                    top: 70px;
                    left: 0;
                    z-index: 99;
                    opacity: 0;
                    width: 250px;
                    display: block;
                    border-radius: 0;
                    padding: 10px 0;
                    margin-top: 15px;
                    position: absolute;
                    visibility: hidden;
                    background: $white-color;
                    transition: all 0.4s ease-in-out;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    li {
                        margin: 0;

                        a {
                            padding: 10px 20px;
                            position: relative;
                            display: block;
                            color: $black-color;
                            text-transform: capitalize;
                            font: {
                                size: $font-size;
                                weight: 500;
                            };
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                right: 15px;
                            }
                            &:hover, &:focus, &.active {
                                color: $main-color;
                                letter-spacing: 1px;
                            }
                        }
                        .dropdown-menu {
                            top: 0;
                            opacity: 0;
                            left: -250px;
                            margin-top: 15px;
                            visibility: hidden;

                            li {
                                a {
                                    color: $black-color;

                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    top: 0;
                                    opacity: 0;
                                    left: 250px;
                                    visibility: hidden;

                                    li {
                                        a {
                                            color: $black-color;

                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            top: 0;
                                            opacity: 0;
                                            left: -250px;
                                            visibility: hidden;

                                            li {
                                                a {
                                                    color: $black-color;

                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    top: 0;
                                                    opacity: 0;
                                                    left: 250px;
                                                    visibility: hidden;

                                                    li {
                                                        a {
                                                            color: $black-color;

                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            top: 0;
                                                            opacity: 0;
                                                            left: -250px;
                                                            visibility: hidden;

                                                            li {
                                                                a {
                                                                    color: $black-color;

                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    left: 250px;
                                                                    visibility: hidden;

                                                                    li {
                                                                        a {
                                                                            color: $black-color;

                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
            }
        }

        .others-options {
            margin-left: 30px;
            .option-item {
                margin-right: 30px;
                position: relative;
                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }
                }
                &::before {
                    position: absolute;
                    right: -15px;
                    top: 0;
                    width: 1px;
                    content: '';
                    height: 28px;
                    background-color: #dadada;
                }
                &:nth-child(2) {
                    &::before {
                        display: none;
                    }
                }
                .search-btn {
                    cursor: pointer;
                    transition: $transition;
                    color: $black-color;
                    font-size: 20px;
                    font-weight: 600;
                }
                .close-btn {
                    cursor: pointer;
                    display: none;
                    transition: $transition;
                    width: 20px;
                    color: $black-color;
                    font-size: 18px;

                    &.active {
                        display: block;
                    }
                }
                .info {
                    position: relative;
                    padding-left: 45px;
                    i {
                        font-size: 30px;
                        color: $main-color;
                        position: absolute;
                        left: 0;
                        top: 8px;
                    }
                    span {
                        color: $paragraph-color;
                    }
                    p {
                        font-weight: 500;
                        margin-bottom: 0;
                        font-family: $heading-font-family;
                        a {
                            color: $black-color;
                        }
                    }
                }
                .default-btn {
                    margin-top: 5px;
                }
            }
        }
    }
}
.navbar-area {
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    }
}
.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        background: $white-color;
        z-index: 2;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 25px;

        .search-form {
            position: relative;

            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $black-color;
                outline: 0;
                transition: $transition;
                padding: {
                    top: 4px;
                    left: 10px;
                }
                &:focus {
                    border-color: $main-color;
                }
            }
            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                transition: $transition;
                padding: 0;

                &:hover, &:focus {
                    color: $main-color;
                }
            }
        }
    }
}
// Mobile and iPad Navbar
@media only screen and (max-width: 1199px) {

    .main-responsive-nav {
        display: block;
        .main-responsive-menu {
            position: relative;
            &.mean-container {
                .mean-nav {
                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: $main-color;
                                }

                                i {
                                    display: none;
                                }
                            }
                            li {
                                a {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    height: 336px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: $black-color;

                    span {
                        background: $black-color;
                    }
                }
            }
        }
        .logo {
            position: relative;
            width: 50%;
            z-index: 999;
        }
    }
    .navbar-area {
        background-color: $white-color;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .main-navbar {
        display: none;
    }

}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner-area {
    background-image: url(assets/img/main-banner.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    position: relative;
    z-index: 1;
    padding: {
        top: 200px;
    };
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: .50;
    }
    .container-fluid {
        max-width: 1470px;
    }
    &.bg-two {
        background-image: url(assets/img/main-banner2.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 870px;
        padding-top: 0;
        &::before {
            display: none;
        }
        .main-banner-content {
            max-width: 720px;
            span {
                color: $main-color;
            }
            h1 {
                color: $black-color;
            }
            p {
                color: $paragraph-color;
                max-width: 650px;
            }
            .banner-btn {
                margin-top: 30px;
            }
        }
        .banner-social-buttons {
            position: absolute;
            left: 45px;
            right: auto;
            top: unset;
            bottom: 50px;
            transform: translateY(-50px);
            transform: unset;

            ul {
                li {
                    color: $black-color;

                    span {
                        margin-right: 30px;
                        position: relative;

                        &::before {
                            position: absolute;
                            right: -38px;
                            top: 10px;
                            width: 30px;
                            content: '';
                            height: 1px;
                            background-color: $black-color;
                        }
                    }
                }
                a {
                    color: $black-color;
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
    &.bg-three {
        background-image: url(assets/img/main-banner3.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 870px;
        padding-top: 0;
        background-attachment: unset;
        &::before {
            display: none;
        }
        .main-banner-content {
            max-width: 100%;
            span {
                color: $main-color;
            }
            h1 {
                color: $black-color;
                font-size: 60px;
                margin-top: 0;
            }
            p {
                color: $paragraph-color;
                max-width: 650px;
            }
            .banner-btn {
                margin-top: 30px;
            }
        }
    }
}

.main-banner-content {
    max-width: 635px;
    span {
        font-size: 18px;
        font-weight: 400;
        color: $white-color;
    }
    h1 {
        font-size: 68px;
        color: $white-color;
        margin: {
            top: 10px;
            bottom: 16px;
        };
    }
    p {
        font-size: 18px;
        color: $white-color;
        margin-bottom: 0;
    }
    .banner-btn {
        margin-top: 45px;
    }
}

.banner-social-buttons {
    position: absolute;
    right: -80px;
    top: 40%;
    transform: translateY(-40%);
    transform: rotate(-90deg);
    z-index: 1;

    ul {
        padding-left: 0;
        margin-bottom: 0;
        li {
            color: $white-color;
            list-style-type: none;
            display: inline-block;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            span {
                margin-right: 30px;
                position: relative;

                &::before {
                    position: absolute;
                    right: -38px;
                    top: 10px;
                    width: 30px;
                    content: '';
                    height: 1px;
                    background-color: $white-color;
                }
            }
        }
        a {
            color: $white-color;

            &:hover {
                color: $main-color;
            }
        }
        i {
            transform-origin: 100% 100%;
            transform: translate(-50%, -50%) rotate(270deg);
        }
    }
}

/*================================================
Approvals Area CSS
=================================================*/
.approvals-area {
    position: relative;
    z-index: 1;
    border-top: 1px solid #9ca2a0;
    margin: {
        top: 200px;
    };
    .container-fluid {
        max-width: 1470px;
    }
    &.bg-color {
        border-top: none;
        margin-top: 0;
        background-color: #fdfdfb;
        .container {
            max-width: 1140px;
        }
    }
}

.col-lg-4 {
    &:last-child {
        .approvals-content {
            &::before {
                display: none;
            }
        }
    }
}

.approvals-content {
    position: relative;
    padding: 35px 20px 35px 60px;
    &::before {
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        content: '';
        height: 100%;
        background-color: #9ca2a0;
    }
    .icon {
        position: absolute;
        left: 0;
        top: 38px;
        i {
            font-size: 40px;
            color: $main-color;
        }
    }
    span {
        font-size: 18px;
        color: #f5f7fa;
    }
    p {
        font-size: 18px;
        color: #f5f7fa;
        margin-bottom: 0;
    }
}

.approvals-item {
    background-color: #f5f4ef;
    padding: {
        left: 50px;
        right: 50px;
    };
    .approvals-content {
        position: relative;
        padding: 20px 20px 20px 60px;
        &::before {
            right: 40px;
            top: 20px;
            height: 60%;
        }
        .icon {
            top: 22px;
        }
        span {
            color: $paragraph-color;
        }
        p {
            color: $paragraph-color;
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    &.bg-color {
        background-color: #fdfdfb;
    }
    .col-lg-7 {
        position: relative;
    }
}

.about-image {
    position: relative;
    text-align: start;
    padding: {
        right: 100px;
        bottom: 100px;
    };
    img {
        border-radius: 0;
        &:nth-child(2) {
            position: absolute;
            right: 10px;
            bottom: 0;
            z-index: 1;
        }
    }
}

.experience {
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    max-width: 470px;
    margin: auto;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
    padding: 40px;
    border-left: 2px solid $main-color;
    h4 {
        font-size: 80px;
        margin-bottom: 0;
        position: absolute;
        position: absolute;
        left: 50px;
        top: 35px;
        font-weight: 400;
    }
    p {
        font-size: 28px;
        color: $black-color;
        font-weight: 400;
        margin-bottom: 0;
        padding-left: 120px;
        line-height: 1.4;
        font-family: $heading-font-family;
    }
}

.about-content {
    span {
        color: $main-color;
    }
    h3 {
        font-size: 36px;
        font-weight: 400;
        margin: {
            top: 10px;
            bottom: 16px;
        };
    }
    strong {
        font-size: 18px;
        color: $paragraph-color;
        font-weight: 400;
    }
    .about-list {
        padding-left: 0;
        margin-top: 20px;
        margin-bottom: 25px;
        li {
            list-style-type: none;
            color: $paragraph-color;
            margin-bottom: 10px;
            position: relative;
            padding-left: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                left: 0;
                top: 0;
                color: $main-color;
                font-size: 20px;
            }
        }
    }
}

.about-wrap {
    position: relative;
    .about-shape {
        background-image: url(assets/img/about/shape.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 200px;
        height: 200px;
        position: absolute;
        bottom: 0;
        right: 60px;

        .text {
            text-align: center;
            margin-top: 50px;
            h3 {
                font-size: 70px;
                color: $main-color;
                margin-bottom: 5px;
                line-height: 1;
            }
            span {
                color: $black-color;
                font-weight: 500;
                font-family: $heading-font-family;
            }
        }
    }
}

/*================================================
Process Area CSS
=================================================*/
.process-area {
    background-color: #f9fafb;
    &.bg-color {
        background-color: #fdfdfb;
        .container {
            max-width: 1140px;
        }
    }
}

.process-item {
    text-align: center;
    background-color: $white-color;
    padding: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: $transition;
    margin: {
        bottom: 30px;
    };
    &::before {
        position: absolute;
        content: "";
        height: 65%;
        width: 90%;
        background-color: #57A695;
        left: 0;
        right: 0;
        margin: auto;
        top: -110px;
        z-index: -1;
        border-radius: 50%;
        transition: $transition;
    }
    .icon {
        margin-bottom: 20px;
        position: relative;
        i {
            display: inline-block;
            height: 90px;
            width: 90px;
            line-height: 90px;
            background-color: $white-color;
            text-align: center;
            font-size: 50px;
            color: $main-color;
            border-radius: 50px;
            transition: $transition;
        }
    }
    h3 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    &:hover {
        transform: translateY(-10px);
        .icon {
            i {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
    &.bg-top1 {
        margin-top: 30px;
    }
    &.bg-top2 {
        margin-top: 0;
    }
    &.bg-top3 {
        margin-top: 0;
    }
    &.bg-top4 {
        margin-top: -30px;
    }
}

.process-item-copy {
  text-align: center;
  background-color: $white-color;
  padding: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  transition: $transition;
  margin: {
    bottom: 30px;
  };
  //&::before {
  //    position: absolute;
  //    content: "";
  //    height: 65%;
  //    width: 90%;
  //    background-color: #57A695;
  //    left: 0;
  //    right: 0;
  //    margin: auto;
  //    top: -110px;
  //    z-index: -1;
  //    border-radius: 50%;
  //    transition: $transition;
  //}
  .icon {
    margin-bottom: 20px;
    position: relative;
    i {
      display: inline-block;
      height: 90px;
      width: 90px;
      line-height: 90px;
      background-color: $white-color;
      text-align: center;
      font-size: 50px;
      color: $main-color;
      border-radius: 50px;
      transition: $transition;
    }
  }
  h3 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  &:hover {
    transform: translateY(-10px);
    .icon {
      i {
        background-color: $main-color;
        color: $white-color;
      }
    }
  }
  &.bg-top1 {
    margin-top: 30px;
  }
  &.bg-top2 {
    margin-top: 0;
  }
  &.bg-top3 {
    margin-top: 0;
  }
  &.bg-top4 {
    margin-top: -30px;
  }
}

.process-item-two {
    background-image: url(assets/img/process-shape.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    height: 280px;
    width: 280px;
    text-align: center;
    margin-bottom: 30px;
    .text {
        position: absolute;
        top: 45px;
        padding: {
            left: 35px;
            right: 35px;
        };
        .icon {
            i {
                font-size: 50px;
                color: $main-color;
            }
        }
        h3 {
            font-size: 24px;
            margin-top: 14px;
            margin-bottom: 10px;
        }
        p {
            font-size: 15px;
            margin-bottom: 0;
        }
    }
}

/*================================================
Company Area CSS
=================================================*/
.company-area {
    .container-fluid {
        padding-left: 0;
    }

    &.bg-color {
        .company-content {
            max-width: 100%;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.company-image {
    background-image: url(assets/img/company.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;

    img {
        display: none;
    }
}

.company-content {
    max-width: 525px;
    padding: {
        top: 80px;
        bottom: 80px;
    };
    h3 {
        font-size: 36px;
        margin-bottom: 50px;
    }
    .company-text {
        position: relative;
        padding-left: 70px;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .icon {
            position: absolute;
            left: 0;
            top: 0;
            i {
                font-size: 50px;
                color: $main-color;
            }
        }
        h4 {
            font-size: 25px;
            margin-bottom: 10px;
        }
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    background-color: #f9fafb;

    &.bg-color {
        background-color: $white-color;

        .services-item {
            background-color: #f9fafb;
        }
    }
}

.services-item {
    text-align: center;
    background-color: $white-color;
    padding: 30px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: $transition;
    margin: {
        bottom: 30px;
    };
    &::before {
        position: absolute;
        content: "";
        height: 65%;
        width: 90%;
        background-color: #57A695;
        left: 0;
        right: 0;
        margin: auto;
        top: -130px;
        z-index: -1;
        border-radius: 50%;
        transition: $transition;
    }
    .icon {
        margin-bottom: 20px;
        position: relative;
        i {
            display: inline-block;
            height: 90px;
            width: 90px;
            line-height: 90px;
            background-color: $white-color;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
            text-align: center;
            font-size: 50px;
            color: $main-color;
            border-radius: 50px;
            transition: $transition;
        }
    }
    h3 {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .learn-btn {
        color: $main-color;
        display: inline-block;
        font-weight: 500;
        font-family: $heading-font-family;
    }
    &:hover {
        transform: translateY(-10px);
        &::before {
            background-color: $main-color;
        }
    }
}

/*================================================
Deserve Area CSS
=================================================*/
.deserve-item {
    h3 {
        font-size: 36px;
        margin-bottom: 0;
        font-weight: 400;
        line-height: 1.4;
    }
    .deserve-content {
        position: relative;
        padding-left: 65px;
        margin-top: 30px;
        &::before {
            width: 1px;
            height: 140%;
            background-color: $main-color;
            content: '';
            z-index: -1;
            position: absolute;
            left: 20px;
            top: 0;
        }
        &:nth-child(5) {
            &::before {
                display: none;
            }
        }
        span {
            display: inline-block;
            height: 40px;
            width: 40px;
            line-height: 40px;
            background-color: #f9fafb;
            border: 1px solid $main-color;
            text-align: center;
            font-size: 24px;
            color: $main-color;
            border-radius: 50px;
            position: absolute;
            left: 0;
            top: 0;
        }
        h4 {
            font-size: 24px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 0;
        }
    }
    .deserve-btn {
        margin-top: 25px;
    }
}

.rate-form {
    padding: 50px 45px;
    background-color: $main-color;

    .rate-content {
        margin-bottom: 35px;
        text-align: center;
        span {
            color: $white-color;
        }
        h3 {
            font-size: 36px;
            color: $white-color;
            margin-top: 10px;
        }
    }

    label {
        color: $white-color;
        font-family: $heading-font-family;
        font-weight: 400;
        text-transform: capitalize;
        font-size: $font-size;
        margin-bottom: 8px;
    }

    .form-group {
        .form-control {
            background-color: $white-color;
            border: none;
            box-shadow: unset;
            height: 50px;
            border-radius: 0;
            color: $paragraph-color;
            width: 100%;
            font: {
                size: $font-size;
            }
        }

        .nice-select {
            float: unset;
            line-height: 52px;
            color: $paragraph-color;
            text-transform: capitalize;
            font: {
                weight: 400;
            }
            padding: {
                top: 0;
                bottom: 0;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: -1px;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: .6s;
                    color: $paragraph-color;
                    font-size: 14px;
                    line-height: 25px;
                    padding: {
                        top: 10px;
                        bottom: 10px;
                        left: 20px;
                        right: 10px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 500;
                    }
                }
            }
            &:after {
                right: 20px;
                border-bottom: 2px solid $black-color;
                border-right: 2px solid $black-color;
            }
        }
    }

    .rate-btn {
        margin-top: 25px;
        .default-btn {
            background-color: $white-color;
            color: $black-color;
            width: 100%;
            padding: 15px 30px;
            cursor: pointer;
        }
    }

    &.bg-fdebeb {
        background-color: #57A695;

        .rate-content {
            span {
                color: $paragraph-color;
            }
            h3 {
                color: $black-color;
            }
        }
        label {
            color: $paragraph-color;
        }
        .rate-btn {
            .default-btn {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
    background-color: #f9fafb;
}
.projects-slider {
    .projects-item {
        .content {
            margin-top: 25px;

            h3 {
                font-size: 25px;
                margin-bottom: 0;
            }
        }
    }
    &.owl-theme {
        .owl-nav {
            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                padding: 0;
                transition: $transition;
                font-size: 18px;
                display: inline-block;
                height: 40px;
                width: 40px;
                line-height: 0px;
                background-color: $white-color;
                color: $black-color;
                box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
                border-radius: 50px;

                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                }
            }
        }
        .owl-nav {
            margin-top: 0;
        }
    }
}

/*================================================
Clients Area CSS
=================================================*/
.clients-area {
    &.bg-color {
        background-color: $main-color;
        .clients-item {
            background-color: unset;
            margin-bottom: 0;
            .clients-image {
                position: relative;
                img {
                    width: auto;
                    margin: auto;
                }
                .icon-1 {
                    position: absolute;
                    bottom: 0;
                    right: 15px;
                    left: auto;
                }
            }
            .clients-info-text {
                max-width: 720px;
                p {
                    color: $white-color;
                }
                h3 {
                    color: $white-color;
                }
                span {
                    color: $white-color;
                }
            }
        }
        .container {
            max-width: 1140px !important;
        }
        .section-title {
            span {
                color: $white-color;
            }
            h2 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
        }

        .clients-slider {
            &.owl-theme {
                .owl-dots {
                    right: 0;
                    .owl-dot {
                        span {
                            border: 1px solid $white-color;

                            &::before {
                                background-color: $white-color;
                            }
                        }
                        &:hover, &.active {
                            span {
                                border: 1px solid $white-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.clients-item {
    background-color: $white-color;
    box-shadow: 1px 14px 20px 0 rgba(231, 60, 62, 0.09);
    padding: 40px;
    position: relative;
    margin-bottom: 30px;
    .info {
        position: relative;
        padding-left: 75px;
        margin-bottom: 30px;
        img {
            position: absolute;
            left: 0;
            top: 0;
            height: 60px;
            width: 60px;
            line-height: 60px;
            border-radius: 50%;
        }
        h4 {
            font-size: $font-size;
            color: $main-color;
            margin-bottom: 16px;
            position: relative;
            top: 10px;
        }
        span {
            font-size: 15px;
        }
    }
    p {
        margin-bottom: 0;
        font-size: 17px;
    }
    .icon {
        position: absolute;
        top: 20px;
        right: 25px;
        i {
           color: #57A695;
           font-size: 100px;
        }
    }
}

.clients-slider {
    .clients-item {
        padding: 0;
        box-shadow: unset;
        .clients-info-text {
            p {
                font-size: 24px;
                color: $black-color;
                font-family: $heading-font-family;
                font-weight: 400;
                margin-bottom: 0;
                line-height: 1.5;
            }
            h3 {
                color: $main-color;
                font-size: $font-size;
                margin-top: 20px;
            }
            span {
                color: $paragraph-color;
            }
        }
        .clients-image {
            position: relative;
            img {
                width: auto;
            }
            .icon-1 {
                position: absolute;
                bottom: 55px;
                left: 80px;
                i {
                    display: inline-block;
                    height: 80px;
                    width: 80px;
                    line-height: 80px;
                    background-color: $white-color;
                    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
                    text-align: center;
                    font-size: 50px;
                    color: $main-color;
                    border-radius: 50px;
                    transition: $transition;
                    cursor: pointer;
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }

    &.owl-theme {
        .owl-dots {
            margin: 0 !important;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 80px;
            display: -ms-grid;
            display: grid;
            .owl-dot {
                span {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    transition: $transition;
                    border-radius: 50%;
                    border: 1px solid $paragraph-color;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: $main-color;
                        border-radius: 50%;
                        margin: 4px;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        transform: scale(0);
                    }
                }
                &:hover, &.active {
                    span {
                        border: 1px solid $main-color;
                        &::before {
                            opacity: 1;
                            visibility: visible;
                            transform: scale(1);
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    background-color: #fafbfc;

    &.bg-color {
        background-color: $white-color;
        border-bottom: 1px solid #e5e5e5;
    }

    &.bg-f5f4ef {
        background-color: #f5f4ef;
    }
}

.partner-item {
    img {
        width: auto !important;
        margin: auto;
    }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    &.bg-color {
        background-color: #fdfcf8;
    }
}

.blog-item {
    transition: $transition;
    margin-bottom: 30px;
    .content {
        margin-top: 25px;
        span {
            font-size: $font-size;
            color: $main-color;
            font-family: $heading-font-family;
        }
        h3 {
            font-size: 25px;
            line-height: 1.4;
            transition: $transition;
            margin: {
                top: 12px;
                bottom: 14px;
            };
        }
        .blog-btn {
            font-size: $font-size;
            font-family: $heading-font-family;
            color: $black-color;
            display: inline-block;
            font-weight: 500;
            transition: $transition;
        }
    }
    &:hover {
        .blog-btn {
            color: $main-color;
            letter-spacing: 1px;
        }
    }
}

.single-blog {
    margin-bottom: 30px;
    .post-meta {
        padding-left: 0;
        margin-top: 25px;
        margin-bottom: 16px;
        li {
            list-style-type: none;
            color: $paragraph-color;
            font-weight: 500;
            display: inline-block;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            i {
                color: $main-color;
                margin-right: 5px;
            }
            a {
                color: $paragraph-color;
            }
        }
    }
    .content {
        h3 {
            font-size: 30px;
            margin-bottom: 14px;
        }
        p {
            margin-bottom: 0;
        }
        .blog-btn {
            margin-top: 20px;
        }
    }
}

/*================================================
Choose Area CSS
=================================================*/
.choose-area {
    background-color: #f9fafb;
}

.choose-title {
    span {
        color: $main-color;
    }
    h2 {
        font-size: 36px;
        margin: {
            top: 10px;
            bottom: 14px;
        };
    }
    p {
        max-width: 570px;
        margin: auto;
    }
}

.choose-image {
    position: relative;
    margin-top: 45px;
    .video-btn {
        display: inline-block;
        width: 80px;
        height: 80px;
        line-height: 90px;
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        left: 45px;
        right: 0;
        margin: auto;
        bottom: -40px;
        transform: translateY(-135%);
        text-align: center;
        i {
            font-size: 55px;
            position: relative;
            top: 10px;
            left: 0;
            color: $main-color;
            transition: $transition;
        }
        &::after {
            z-index: -1;
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            width: 80px;
            height: 80px;
            -webkit-animation: ripple 1.6s ease-out infinite;
            animation: ripple 1.6s ease-out infinite;
            opacity: 0;
            border-radius: 50%;
            background: $main-color;
        }
        &:hover, .video-content .video-btn:focus {
            background-color: $main-color;
            color: $white-color !important;
            i {
                color: $white-color;
            }
        }
    }

    @-webkit-keyframes ripple {
        0%, 35% {
            transform: scale(0);
            opacity: 1;
        }
        50% {
            transform: scale(1.5);
            opacity: 0.8;
        }
        100% {
            opacity: 0;
            transform: scale(2);
        }
    }

    @keyframes ripple {
        0%, 35% {
            transform: scale(0);
            opacity: 1;
        }
        50% {
            transform: scale(1.5);
            opacity: 0.8;
        }
        100% {
            opacity: 0;
            transform: scale(2);
        }
    }
}

/*================================================
Solution Area CSS
=================================================*/
.solution-item {
    background-color: $main-color;
    border-radius: 5px;
    padding: {
        top: 65px;
        bottom: 65px;
        left: 50px;
        right: 50px;
    };
    .content {
        position: relative;
        &::before {
            position: absolute;
            right: -35px;
            top: 0;
            width: 1px;
            content: '';
            height: 85px;
            background-color: #cadbf3;
        }
        h3 {
            font-size: 36px;
            color: $white-color;
            font-weight: 400;
        }
    }
    .solution-info {
        position: relative;
        padding-left: 85px;
        text-align: end;
        i {
            display: inline-block;
            height: 70px;
            width: 70px;
            line-height: 70px;
            text-align: center;
            border-radius: 50px;
            background-color: $white-color;
            color: $main-color;
            font-size: 30px;
            position: absolute;
            left: 50px;
            top: -14px;
        }
        h3 {
            font-size: 36px;
            margin-bottom: 0;
            a {
                color: $white-color;
            }
        }
    }
}

/*================================================
Best Services Area CSS
=================================================*/
.best-services-area {
    background-color: #f9fafb;
    &.bg-color {
        background-color: $white-color;
    }
}
.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}
.tabs-list {
    padding-left: 0;
    list-style-type: none;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: {
        left: -5px;
        right: -5px;
        bottom: 45px;
    }
    li {
        flex: 1 0 15.666667%;
        max-width: 20.666667%;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: $black-color;
        cursor: pointer;
        margin: {
            right: 5px;
            left: 5px;
        }
        span {
            display: block;
            margin-top: 10px;
            font-weight: bold;
            font-size: 20px;
            color: $black-color;
            transition: $transition;
        }
        i {
            font-size: 60px;
            line-height: 1;
            color: $black-color;
            transition: $transition;
        }

        &.current {
            span {
                color: $main-color;
            }
            i {
                color: $main-color;
            }
        }
    }
}

.services-list-tab {
    .tabs_item {
        .services-tab-content {
            margin-bottom: 20px;
            h3 {
                font-size: 25px;
                margin-bottom: 14px;
            }
            p {
                margin-bottom: 0;
            }
            &.left {
                margin-bottom: 0;
            }
        }
        .list {
            padding-left: 0;
            margin-bottom: 20px;
            h3 {
                font-size: 25px;
                margin-bottom: 20px;
            }
            li {
                color: $paragraph-color;
                font-weight: 400;
                font-family: $heading-font-family;
                list-style-type: none;
                margin-bottom: 10px;
                position: relative;
                padding-left: 18px;
                &::before {
                    position: absolute;
                    content: "";
                    height: 10px;
                    width: 10px;
                    left: 0;
                    top: 5px;
                    border-radius: 30px;
                    background-color: $main-color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.best-services-slider {
    position: relative;
    #thumbs {
        border-bottom: 1px solid #cbcbcc;
        margin-bottom: 40px;

        .current {
            .tabs-item {
                border-bottom: 1px solid $main-color;
                i {
                    color: $main-color;
                }
            }
        }
    }
    .tabs-item {
        transition: $transition;
        a {
            display: inline-block;
            text-transform: capitalize;
            font-size: $font-size;
            font-weight: 500;
            color: $black-color;
            transition: $transition;
            width: 100%;
            text-align: center;
            font-family: $heading-font-family;
        }
        i {
            display: inline-block;
            height: 80px;
            width: 80px;
            line-height: 80px;
            background-color: $white-color;
            box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
            text-align: center;
            font-size: 45px;
            color: $paragraph-color;
            border-radius: 50px;
            transition: $transition;
        }
        span {
            display: block;
            margin-top: 10px;
            font-weight: bold;
            font-size: 20px;
            padding-bottom: 30px;
        }
    }

    .owl-theme {
        .owl-nav {
            [class*=owl-] {
                position: absolute;
                left: 0;
                top: 95%;
                transform: translateY(-28%);
                margin: 0;
                padding: 0;
                transition: $transition;
                font-size: 18px;
                display: inline-block;
                height: 40px;
                width: 40px;
                line-height: 0px;
                background-color: $white-color;
                color: $black-color;
                border: 1px solid #cbcbcc;
                border-radius: 50px;
                z-index: 1;
                &.owl-next {
                    left: auto;
                    right: 0;
                }
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border: 1px solid $main-color;
                }
            }
        }
        .owl-nav {
            margin-top: 0;
        }
    }

    .services-tabs-item {
        .services-tab-content {
            margin-bottom: 20px;
            h3 {
                font-size: 25px;
                margin-bottom: 14px;
                font-weight: 500;
            }
            p {
                margin-bottom: 0;
            }
            &.left {
                margin-bottom: 0;
            }
        }
        .list {
            padding-left: 0;
            margin-bottom: 20px;
            h3 {
                font-size: 25px;
                margin-bottom: 20px;
                font-weight: 500;
            }
            li {
                color: $paragraph-color;
                font-weight: 400;
                font-family: $heading-font-family;
                list-style-type: none;
                margin-bottom: 10px;
                position: relative;
                padding-left: 18px;
                &::before {
                    position: absolute;
                    content: "";
                    height: 10px;
                    width: 10px;
                    left: 0;
                    top: 5px;
                    border-radius: 30px;
                    background-color: $main-color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    background-color: #fafbfc;

    &.bg-color {
        background-color: $white-color;
    }
}

.team-item {
    margin-bottom: 30px;
    .image {
        position: relative;
        overflow: hidden;
        transition: $transition;
        .social {
            padding-left: 0;
            list-style-type: none;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 20px;
            text-align: center;
            margin: {
                left: auto;
                right: auto;
                bottom: 0;
            };
            li {
                display: inline-block;
                transform: translateY(30px);
                transition: all 0.3s ease-in-out 0s;
                opacity: 0;
                visibility: hidden;
                margin: {
                    left: 1px;
                    right: 1px;
                };
                a {
                    display: block;
                    width: 35px;
                    height: 35px;
                    background-color: #f4f5fe;
                    text-align: center;
                    position: relative;
                    font-size: 20px;
                    color: $black-color;
                    border-radius: 30px;
                    transition: $transition;
                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        font-weight: bold;
                        margin: {
                            left: auto;
                            right: auto;
                        };
                    }
                    &:hover {
                        color: $white-color;
                        background-color: $main-color;
                    }
                }
                &:nth-child(1) {
                    transition-delay: 0.2s;
                }
                &:nth-child(2) {
                    transition-delay: 0.3s;
                }
                &:nth-child(3) {
                    transition-delay: 0.4s;
                }
                &:nth-child(4) {
                    transition-delay: 0.5s;
                }
            }
        }
    }
    .content {
        margin-top: 25px;
        h3 {
            font-size: 20px;
            color: $main-color;
            margin-bottom: 8px;
        }
        span {
            font-size: $font-size;
            color: $paragraph-color;
        }
    }
    &:hover {
        .image {
            .social {
                li {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

/*================================================
Fun Facts Area CSS
=================================================*/
.fun-facts-area {
    background-color: #f5f4ef;
}

.col-lg-3 {
    &:last-child {
        .single-fun-fact {
            border-right: none;
        }
    }
}

.single-fun-fact {
    text-align: center;
    position: relative;
    border-right: 1px solid #d3d2ce;
    margin: {
        bottom: 30px;
    };
    h3 {
        position: relative;
        margin-bottom: 5px;
        line-height: 1;
        color: $main-color;
        font: {
            size: 48px;
        }
        .sign-icon {
            display: inline-block;
            font-size: 48px;
            margin-left: -10px;
            position: relative;
            top: 2px;
        }
        .odometer {
            font-family: $main-font-family;
        }
    }
    p {
        line-height: initial;
        color: $black-color;
        margin-top: 10px;
        margin-bottom: 0;
        line-height: 1;
        font: {
            weight: 500;
            size: 24px;
            family: $heading-font-family;
        }
    }
}

/*================================================
Value Area CSS
=================================================*/
.value-content {
    span {
        color: $main-color;
    }
    h3 {
        font-size: 36px;
        font-weight: 400;
        margin: {
            top: 10px;
            bottom: 14px;
        };
    }
    .text {
        margin-bottom: 0;
    }
}

.value-features {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    list-style-type: none;
    margin: {
        bottom: 0;
        left: -10px;
        right: -10px;
    }
    li {
        -ms-flex: 0 0 50%;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
        margin-top: 25px;
        font-size: 20px;
        font-family: $heading-font-family;
        font-weight: 500;
        padding: {
            left: 10px;
            right: 10px;
        }
        i {
            display: inline-block;
            height: 35px;
            width: 35px;
            line-height: 35px;
            background-color: $main-color;
            text-align: center;
            color: $white-color;
            border-radius: 50px;
            font-weight: 500;
            font-size: 20px;
            margin-right: 10px;
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    background-attachment: fixed;

    &.item-bg-1 {
        background-image: url(assets/img/page-title/bg.png);
    }
    &.item-bg-2 {
        background-image: url(assets/img/page-title/bg-2.jpg);
    }
    &.item-bg-3 {
        background-image: url(assets/img/page-title/bg-3.jpg);
    }
    &.item-bg-4 {
        background-image: url(assets/img/page-title/bg-4.jpg);
    }
    &.item-bg-5 {
        background-image: url(assets/img/page-title/bg-5.jpg);
    }
    &.item-bg-6 {
        background-image: url(assets/img/page-title/bg-6.jpg);
    }
    &.item-bg-7 {
        background-image: url(assets/img/page-title/bg-7.jpg);
    }
    &.item-bg-8 {
        background-image: url(assets/img/page-title/bg-8.jpg);
    }
    &.item-bg-9 {
        background-image: url(assets/img/page-title/bg-9.jpg);
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: $white-color;
        opacity: .77;
    }
}
.page-title-content {
    text-align: center;
    margin-top: -10px;
    h2 {
        margin-bottom: 0;
        color: $black-color;
        font-family: $main-font-family;
        font-size: 48px;
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 12px;
            bottom: 0;
        };
        li {
            color: $paragraph-color;
            display: inline-block;
            position: relative;
            font-family: $heading-font-family;
            font: {
                weight: 500;
                size: 17px;
            };
            margin: {
                left: 12px;
                right: 12px;
            };
            a {
                display: inline-block;
                color: $main-color;
                transition: $transition;
                &:hover {
                    color: $main-color;
                }
            }
            &::before {
                position: absolute;
                right: -12px;
                top: 4px;
                width: 1px;
                content: '';
                height: 18px;
                background-color: $paragraph-color;
                transform: rotate(20deg);
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Gallery Area CSS
=================================================*/
.gallery-item {
    margin-bottom: 30px;
    .content {
        margin-top: 25px;
        h3 {
            font-size: 25px;
            margin-bottom: 0;
        }
    }
}

.gallery-btn {
    text-align: center;
    margin-top: 20px;
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-area {
    .section-title {
        h2 {
            margin-top: 0;
            margin-bottom: 14px;
        }
        b {
            font-size: 18px;
            color: $paragraph-color;
            font-family: $heading-font-family;
            font-weight: 400;
        }
    }
    .container {
        max-width: 1140px;
    }
}
.faq-list-tab {
    .tabs-list {
        padding-left: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: {
            bottom: 40px;
        }
        li {
            flex: 1 0 32.33333333333333%;
            max-width: 33.33333333333333%;
            text-align: center;
            a {
                display: inline-block;
                font-size: $font-size;
                font-weight: 500;
                font-family: $heading-font-family;
                color: $white-color;
                transition: $transition;
                width: 100%;
                text-align: center;
                background-color: $black-color;
                padding: 14px 30px;
                border-radius: 50px;
            }
            &.current {
                a {
                    color: $white-color;
                    background-color: $main-color;
                }
            }
        }
    }
    .tab_content {
        .tabs_item {
            .faq-accordion {
                max-width: 960px;
                margin: auto;

                .accordion {
                    list-style-type: none;
                    padding-left: 0;
                    margin-bottom: 0;

                    .accordion-item {
                        display: block;
                        background: $white-color;
                        margin-bottom: 10px;
                        border: 0;

                        &:last-child {
                            margin-bottom: 0;
                        }
                        &.active {
                            .accordion-content {
                                display: block;
                            }
                            .accordion-title {
                                i {
                                    &::before {
                                        content: "\f12d";
                                    }
                                }
                            }
                        }
                    }
                    .accordion-title {
                        padding: 10px 0 10px 0;
                        color: $black-color;
                        position: relative;
                        border-bottom: 1px solid #e7e7e7;
                        margin-bottom: -1px;
                        display: inline-block;
                        width: 100%;
                        text-transform: capitalize;
                        font: {
                            size: 18px;
                            weight: 700;
                            family: $heading-font-family;
                        }
                        i {
                            font-size: 18px;
                            margin-right: 10px;
                        }
                    }
                    .accordion-content {
                        display: none;
                        position: relative;
                        padding: 15px 0 0 0;
                    }
                }
            }
        }
    }
}
.faq-contact {
    margin-top: 100px;

    .section-title {
        max-width: 100%;

        h2 {
            font-size: 36px;
        }
    }
}
.faq-contact-form {
    max-width: 950px;
    margin: 0 auto;
    text-align: center;

    form {
        .form-control {
            background-color: $white-color;
            border: 1px solid #b7b7b7;
            box-shadow: unset;
            height: 50px;
            border-radius: 2px;
            font: {
                size: 16px;
            }
        }
        .row {
            margin: {
                left: -7px;
                right: -7px;
            }
            .col-lg-12, .col-lg-6 {
                padding: {
                    left: 7px;
                    right: 7px;
                }
            }
        }
        textarea.form-control {
            height: 200px !important;
            padding-top: 15px;
        }
        .help-block {
            ul {
                padding-left: 0;
                list-style-type: none;
                text-align: start;
                margin: {
                    top: 5px;
                    bottom: 0;
                }
                li {
                    color: red;
                }
            }
        }
        #msgSubmit {
            margin-bottom: 0;

            &.text-danger, &.text-success {
                margin-top: 16px;
                font: {
                    size: 20px;
                    weight: 700;
                }
            }
        }
    }
}

/*================================================
Loan Calculator Area CSS
=================================================*/
.calculator-form {
    background-color: $main-color;
    padding: 50px;
    max-width: 600px;
    margin: auto;
    .text {
        text-align: center;
        margin-bottom: 35px;
        span {
            color: $white-color;
        }
        h3 {
            font-size: 36px;
            margin-bottom: 0;
            color: $white-color;
            font-weight: 400;
        }
    }
    .form-group {
        label {
            color: $white-color !important;
        }
        .form-control {
            background-color: transparent;
            border: 1px solid #cadbf3;
            box-shadow: unset;
            height: 50px;
            border-radius: 0;
            color: #cadbf3;
            font: {
                size: 16px;
            }
        }
    }
    .calculator-btn {
        text-align: center;
        margin-top: 25px;
        .btn-one {
            display: inline-block;
            padding: 12px 60px;
            background-color: $white-color;
            color: $black-color;
            border-radius: 5px;
            font-family: $heading-font-family;
            transition: $transition;
            margin-right: 15px;
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
        .btn-two {
            display: inline-block;
            padding: 12px 30px;
            background-color: $white-color;
            color: $black-color;
            border-radius: 5px;
            font-family: $heading-font-family;
            transition: $transition;
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Table Area CSS
=================================================*/
.table-area {
    .container {
        max-width: 1140px;
    }
}

.table {
    margin-bottom: 0;
    thead {
        th {
            border: none;
            background-color: #f9fafb;
            color: $black-color;
            font-family: $heading-font-family;
            font-weight: 600;
            font-size: $font-size;
        }
    }
}

.table-bordered td, .table-bordered th {
    border: 1px solid #f0f0f0;
    font-weight: 400;
    color: $paragraph-color;
    overflow-x:auto;
}

.table-striped {
    tbody {
        tr {
            &:nth-of-type(odd) {
                background-color: unset;
            }
        }
    }
}

.text-nowrap {
    font-weight: 400;
    color: $paragraph-color;
}

.table-responsive {
    overflow-x: unset;
}

/*================================================
Apply Area CSS
=================================================*/
.apply-area {
    .container {
        max-width: 1140px;
    }
}

.apply-title {
    margin-bottom: 45px;
    text-align: center;
    h3 {
        font-size: 36px;
        margin-bottom: 0;
    }
}

.apply-form {
    .form-group {
        label {
            color: $paragraph-color !important;
            font-family: $heading-font-family;
            font-weight: 400;
        }
        .form-control {
            background-color: transparent;
            border: 1px solid #cfcfcf;
            box-shadow: unset;
            height: 55px;
            border-radius: 0;
            color: #cfcfcf;
            font: {
                size: 16px;
            }
        }

        .nice-select {
            float: unset;
            line-height: 53px;
            color: #666666;
            font: {
                weight: 500;
            }
            padding: {
                top: 0;
                bottom: 0;
            }
            .list {
                background-color: $white-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding: {
                    top: 10px;
                    bottom: 10px;
                }
                .option {
                    transition: .6s;
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;
                    }
                    &.selected {
                        background-color: transparent;
                        font-weight: 600;
                    }
                }
            }
            &:after {
                right: 20px;
            }
        }
    }
}

.apply-btn {
    text-align: center;
    margin-top: 20px;

    .default-btn {
        padding: 14px 50px;
    }
}

/*================================================
Projects Details CSS
=================================================*/
.projects-details-image {
    margin-bottom: 30px;
}

.projects-details-content {
    margin-top: 5px;

    h3 {
        font-size: 25px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    h4 {
        font-size: 25px;
        margin-bottom: 10px;
        font-weight: 600;
    }
    ul, ol {
        padding-left: 17px;
        li {
            line-height: 1.7;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .projects-details-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-top: 30px;
        background: #f4f5fe;
        padding: 40px 20px 30px;
        border-radius: 10px;

        .single-info-box {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
            padding: {
                left: 15px;
                right: 15px;
            }
            h4 {
                margin-bottom: 10px;
                font: {
                    size: 18px;
                    weight: 600;
                }
            }
            span {
                display: block;
                color: $paragraph-color;
                font-size: 15px;
            }
            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
                li {
                    display: inline-block;
                    margin-right: 5px;
                    a {
                        background-color: #ffffff;
                        color: $paragraph-color;
                        display: inline-block;

                        width: 30px;
                        height: 30px;
                        text-align: center;
                        border-radius: 30px;
                        line-height: 32px;
                        font-size: 14px;

                        &:hover {
                            color: $main-color;
                            transform: translateY(-2px);
                        }
                    }
                }
            }
            &:last-child {
                text-align: center;
            }
        }
    }
}

/*================================================
Sign In CSS
=================================================*/
.sign-in-form {
    background: #ffffff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;

    .sign-in-title {
        text-align: center;
        margin-bottom: 30px;

        h3 {
            font-size: 30px;
            font-family: $main-font-family;
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 30px;
        }
    }

    form {
        .form-control {
            height: 50px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: $black-color;
            padding-left: 20px;
            background: transparent;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }

            &::placeholder {
                color: #666666;
            }
        }

        .form-check {
            margin-top: 18px;

            .form-check-label {
                color: #666666;
                position: relative;
                top: -2px;
                z-index: 1;
            }
        }
        .form-check-input {
            margin-top: 3px;
        }
        .send-btn {
            margin: {
                top: 20px;
            }
            .default-btn {
                color: $white-color;
                cursor: pointer;
            }
        }
        span {
            display: block;
            color: #777777;

            a {
                color: $main-color;
            }
        }
    }
    .forgot-password {
        margin-bottom: 0;
        margin-top: -25px;

        a {
            color: #777777;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*================================================
Sign Up CSS
=================================================*/
.signup-form {
    background: #ffffff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 40px;
    border-radius: 5px;
    max-width: 600px;
    margin: auto;

    h3 {
        font-size: 30px;
        font-family: $main-font-family;
        margin-bottom: 30px;
        text-align: center;
    }

    form {
        .form-control {
            height: 50px;
            border: 1px solid #eeeeee;
            border-radius: 3px;
            color: $black-color;
            padding-left: 20px;
            background: transparent;
            &:focus {
                border-color: $main-color;
                outline: 0;
                box-shadow: none;
            }

            &::placeholder {
                color: #666666;
            }
        }

        .form-check {
            margin-top: 18px;

            .form-check-label {
                color: #666666;
                position: relative;
                top: 0;
            }
        }
        .form-check-input {
            margin-top: 4px;
        }
        .send-btn {
            margin: {
                top: 20px;
            }
            .default-btn {
                color: $white-color;
                cursor: pointer;
            }
        }
        span {
            display: block;
            color: #666666;

            a {
                color: $main-color;
            }
        }
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    border-top: 1px solid #eeeeee;
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        font-size: 50px;
        font-weight: 500;
        margin: {
            top: 45px;
            bottom: 15px;
        }
    }
    p {
        max-width: 520px;
        margin: 0 auto 20px;
    }
    .default-btn {
        display: inline-block;
        background-color: $main-color;
        padding: 14px 30px;
        border-radius: 30px;
        color: $white-color;
        transition: $transition;

        &:hover {
            background-color: $optional-color;
        }
    }
}

/*================================================
Terms of Service Area CSS
=================================================*/
.terms-of-condition-area {
    .container {
        max-width: 1140px;
    }
}

.terms-of-condition-content {
    h3 {
        margin: {
            bottom: 15px;
            top: 30px;
        };
        font: {
            size: 25px;
        };
    }
    p {
        margin-bottom: 0;
    }
}

.terms-of-condition-sidebar {
    background-color: $white-color;
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    padding: 25px;

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: -1px;

            a {
                position: relative;
                display: block;
                padding: 15px 25px 15px 40px;
                color: $black-color;
                border: 1px solid #eeeeee;
                font: {
                    weight: 500;
                    size: 18px;
                };
                &::before {
                    width: 5px;
                    height: 5px;
                    transition: $transition;
                    background-color: $main-color;
                    content: '';
                    position: absolute;
                    left: 25px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                }
                &:hover, &.active {
                    border-color: $main-color;
                    background-color: $main-color;
                    color: $white-color;

                    &::before {
                        background-color: $white-color;
                    }
                }
            }
            &.active {
                a {
                    border-color: $main-color;
                    background-color: $main-color;
                    color: $white-color;

                    &::before {
                        background-color: $white-color;
                    }
                }
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area {
    .container {
        max-width: 1140px;
    }
    .section-title {
        max-width: 530px;
        margin: 0 auto 40px;

        h2 {
            line-height: 1.4;
            margin-bottom: 0;
        }
    }
}
.contact-form {
    .title {
        margin-bottom: 30px;
        h3 {
            font-size: 25px;
            margin-bottom: 0;
        }
    }
    .form-group {
        label {
            font-size: $font-size;
            color: $paragraph-color;
            font-family: $heading-font-family;
            font-weight: 500;
        }
        .form-control {
            background-color: transparent;
            border: 1px solid #cfcfcf;
            box-shadow: unset;
            height: 50px;
            border-radius: 0;
            color: #cfcfcf;
            font-size: $font-size;
        }
        textarea {
            height: 150px !important;
        }
    }
}
.contact-side-box {
    .title {
        margin-bottom: 30px;

        h3 {
            font-size: 25px;
            margin-bottom: 0;
        }
    }
    .info-box {
        position: relative;
        margin-bottom: 20px;
        padding-left: 50px;

        &:last-child {
            margin-bottom: 0;
        }
        .icon {
            position: absolute;
            left: 0;
            top: 0;

            i {
                font-size: 30px;
                color: $main-color;
            }
        }
        h4 {
            font-size: 20px;
            margin-bottom: 10px;
        }
        .list {
            padding-left: 0;
            margin-bottom: 0;

            li {
                color: $paragraph-color;
                margin-bottom: 5px;
                list-style-type: none;

                &:last-child {
                    margin-bottom: 0;
                }
                span {
                    float: right;
                }
            }
        }
        span {
            color: $paragraph-color;
            display: block;
            margin-bottom: 7px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            color: $paragraph-color;
            display: inline-block;

            &:hover {
                color: $main-color;
            }
        }
    }
}
.map {
    iframe {
        border: none;
        height: 485px;
        width: 100%;
        margin-bottom: -8px;
    }
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}

/*================================================
Sidebar Widget Area CSS
=================================================*/
.widget-area {
    .widget {
        margin-top: 35px;
        &:first-child {
            margin-top: 0;
        }
        .widget-title {
            border-bottom: 1px solid $main-color;
            padding-bottom: 10px;
            margin-bottom: 25px;
            text-transform: capitalize;
            position: relative;
            font-size: 25px;
        }
    }

    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 25px;
        form {
            position: relative;
            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #ecf3ff;
                width: 100%;
                display: block;
                outline: 0;
                transition: $transition;
                &:focus {
                    border-color: #ecf3ff;
                    background-color: transparent;
                }
            }
            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                line-height: 55px;
                z-index: 1;
                border: none;
                color: $white-color;
                background-color: $main-color;
                transition: $transition;
                cursor: pointer;
                &:hover {
                    background-color: $black-color;
                    color: $white-color;
                }
                i {
                    font-size: 20px;
                }
            }
        }
    }

    .widget_categories {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 25px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                text-transform: capitalize;
                border-bottom: 1px solid #e7e7e7;
                padding-bottom: 10px;
                font: {
                    size: $font-size;
                    weight: 400;
                    family: $heading-font-family
                }
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $optional-color;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }

    .widget_hours {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 25px;

        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                text-transform: capitalize;
                border-bottom: 1px solid #e7e7e7;
                padding-bottom: 10px;
                font: {
                    size: $font-size;
                    weight: 400;
                    family: $heading-font-family
                }
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $optional-color;
                        letter-spacing: 1px;
                    }
                }
                span {
                    float: right;
                }
            }
        }
    }

    .widget_appointment {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 25px;

        .form-group {
            .form-control {
                background-color: $white-color;
                border: 1px solid #e7e7e7;
                box-shadow: unset;
                height: 50px;
                border-radius: 2px;
                font-size: $font-size;
                color: #e7e7e7;
            }
            .list-unstyled {
                color: red;
                margin-top: 5px;
            }
        }
        .text-danger {
            color: #dc3545!important;
            font-size: 20px !important;
            margin-top: 15px !important;
        }
    }

    .widget_finix_posts_thumb {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 25px;
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;
                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }
                    &.bg1 {
                        background-image: url(assets/img/blog/image1.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog/image2.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog/image3.jpg);
                    }
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;
                time {
                    display: block;
                    color: $paragraph-color;
                    text-transform: capitalize;
                    margin: {
                        top: 5px;
                    }
                    font: {
                        size: 14px;
                        weight: 500;
                    }
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font-size: 16px;
                    font-weight: bold;
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .widget_tag_cloud {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 25px;
    }

    .tagcloud {
        a {
            display: inline-block;
            color: $black-color;
            font: {
                weight: 400;
                size: 14.5px !important;
            }
            padding: 6px 12px;
            border: 1px solid #d7d7d7;
            background-color: transparent;
            color: $paragraph-color;
            margin: {
                top: 8px;
                right: 4px;
            }
            &:hover, &:focus {
                color: $white-color;
                background-color: $main-color;
                border-color: $main-color;
                border: 1px solid $main-color;
            }
        }
    }

    .widget_archives {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: $white-color;
        padding: 25px;
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            li {
                position: relative;
                margin-bottom: 12px;
                color: $paragraph-color;
                text-transform: capitalize;
                border-bottom: 1px solid #e7e7e7;
                padding-bottom: 10px;
                font: {
                    size: $font-size;
                    weight: 400;
                    family: $heading-font-family
                }
                &:last-child {
                    margin-bottom: 0;
                    padding-bottom: 0;
                    border-bottom: none;
                }
                a {
                    color: $black-color;
                    display: block;

                    &:hover {
                        color: $optional-color;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }

}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-desc {
    .image {
        margin-bottom: 30px;
    }
    .content {
        h3 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        p {
            font-family: $heading-font-family;
            font-weight: 400;
        }
        .text {
            margin-bottom: 0;
            font-family: $heading-font-family;
            font-weight: 400;
        }
    }
    .deserve-item {
        h3 {
            font-size: 30px;
        }
    }
}

.services-details-features {
    padding: 50px 30px;
    background-color: #f9fafb;
    margin-top: 35px;
    .content {
        margin-bottom: 35px;
        h3 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        p {
            font-family: $heading-font-family;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
    .features-item {
        text-align: center;
        .icon {
            margin-bottom: 20px;
            i {
                display: inline-block;
                height: 100px;
                width: 100px;
                line-height: 100px;
                background-color: $white-color;
                font-size: 50px;
                color: $main-color;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                text-align: center;
                border-radius: 50px;
            }
        }
        h3 {
            font-size: 25px;
            margin-bottom: 0;
            line-height: 1.4;
        }
    }
}

.services-details-others {
    margin-top: 35px;
    margin-bottom: 25px;
    h3 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    p {
        font-family: $heading-font-family;
        font-weight: 400;
    }
}

.services-details-list {
    margin-top: 35px;
    .content {
        h3 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        p {
            font-family: $heading-font-family;
            font-weight: 400;
            margin-bottom: 0;
        }
        .list {
            padding-left: 0;
            margin-top: 16px;
            margin-bottom: 0;
            li {
                list-style-type: none;
                color: $paragraph-color;
                font-weight: 400;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
                i {
                    color: $main-color;
                    margin-right: 5px;
                }
            }
        }
    }
}

/*================================================
Funding Area CSS
=================================================*/
.funding-content {
    span {
        color: $main-color;
    }
    h3 {
        font-size: 36px;
        font-weight: 400;
        margin: {
            top: 10px;
            bottom: 16px;
        };
    }
    strong {
        font-size: 18px;
        color: $black-color;
        font-weight: 500;
        font-family: $heading-font-family;
    }
    .funding-list {
        padding-left: 0;
        margin-top: 20px;
        margin-bottom: 0;

        li {
            list-style-type: none;
            color: $paragraph-color;
            margin-bottom: 10px;
            position: relative;
            font-family: $heading-font-family;

            &:last-child {
                margin-bottom: 0;
            }
            span {
                color: $main-color;
                margin-right: 4px;
            }
            b {
                color: $black-color;
            }
        }
    }
}
.funding-image {
    img {
        height: 100%;
    }
}

/*================================================
Works Area CSS
=================================================*/
.works-area {
    position: relative;
    z-index: 1;
    .section-title {
        max-width: 570px;
        margin: 0 auto 45px;

        h {
            margin-bottom: 0;
            line-height: 1.4;
        }
    }
    .container {
        max-width: 1140px;
    }
}

.works-item {
    text-align: center;
    margin-bottom: 30px;
    .icon {
        margin-bottom: 20px;
        i {
            display: inline-block;
            height: 100px;
            width: 100px;
            line-height: 100px;
            background-color: $white-color;
            font-size: 50px;
            color: $main-color;
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            text-align: center;
            border-radius: 50px;
        }
    }
    h3 {
        font-size: 25px;
        margin-bottom: 0;
        line-height: 1.4;
    }
}

.works-shape {
    .shape {
        position: absolute;
        left: 31%;
        top: 60%;
        transform: translateY(-60%);
    }
    .shape2 {
        position: absolute;
        right: 31%;
        top: 55%;
        transform: translateY(-55%);
    }
}

/*================================================
Funding Form Area CSS
=================================================*/
.funding-form-area {
    background-color: #f9fafb;
    .container {
        max-width: 870px;
    }
    .section-title {
        max-width: 540px;
        margin: 0 auto 45px;

        h {
            margin-bottom: 0;
            line-height: 1.4;
        }
    }
}

.funding-form {
    .form-group {
        label {
            color: $paragraph-color !important;
            font-family: $heading-font-family;
            font-weight: 400;
        }
        .form-control {
            background-color: transparent;
            border: 1px solid #cbcbcc;
            box-shadow: unset;
            height: 50px;
            border-radius: 0;
            color: #cbcbcc;
            font: {
                size: 16px;
            }
        }
    }
}

.buy-checkbox-btn {
    margin-top: 10px;
    input {
        display: none;
    }
    .cbx {
        margin: auto;
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        span {
            display: inline-block;
            vertical-align: middle;
            transform: translate3d(0, 0, 0);
            &:first-child {
                position: relative;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                transform: scale(1);
                vertical-align: middle;
                border: 1px solid $paragraph-color;
                transition: all 0.2s ease;
                transition: .6s;
                svg {
                    position: absolute;
                    top: 3px;
                    left: 2px;
                    fill: none;
                    stroke: $white-color;
                    stroke-width: 2;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-dasharray: 16px;
                    stroke-dashoffset: 16px;
                    transition: all 0.3s ease;
                    transition-delay: 0.1s;
                    transform: translate3d(0, 0, 0);
                    transition: .6s;
                }
                &:before {
                    content: "";
                    width: 100%;
                    height: 100%;
                    background: $black-color;
                    display: block;
                    transform: scale(0);
                    opacity: 1;
                    border-radius: 50%;
                    transition: .6s;
                }
            }
            &:last-child {
                position: relative;
                top: 2px;
                padding-left: 4px;
                color: $paragraph-color;
                font-weight: 400;
                font-family: $heading-font-family;
                a {
                    color: $main-color;
                }
            }
        }
        &:hover {
            span {
                &:first-child {
                    border-color: $main-color;
                }
            }
        }
    }
    .inp-cbx:checked + .cbx span:first-child {
        background: $main-color;
        border-color: $main-color;
        animation: wave 0.4s ease;
    }
    .inp-cbx:checked + .cbx span:first-child svg {
        stroke-dashoffset: 0;
    }
    .inp-cbx:checked + .cbx span:first-child:before {
        transform: scale(3.5);
        opacity: 0;
        transition: all 0.6s ease;
    }
    .item {
        &:not(:first-child) {
            margin-top: 15px;
        }
    }
}

.funding-btn {
    margin-top: 25px;
}

/*================================================
Loan Area CSS
=================================================*/
.loan-area {
    .container {
        max-width: 1140px;
    }
}

.loan-image {
    .loan-shape {
        background-image: url(assets/img/about/shape.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 200px;
        height: 200px;
        position: absolute;
        bottom: 0;
        right: 60px;

        .text {
            text-align: center;
            margin-top: 50px;

            img {
                display: block;
                margin: auto;
                margin-bottom: 8px;
            }
            span {
                color: $black-color;
                font-weight: 500;
                font-family: $heading-font-family;
                font-size: 18px;
            }
        }
    }
}

.loan-content {
    h3 {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 12px;
    }
    p {
        margin-bottom: 0;
    }
    .list {
        padding-left: 0;
        margin-top: 16px;
        margin-bottom: 18px;
        li {
            position: relative;
            margin-bottom: 12px;
            color: $paragraph-color;
            padding-left: 18px;
            text-transform: capitalize;
            list-style-type: none;
            font: {
                size: $font-size;
                weight: 500;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                background: $optional-color;
                height: 8px;
                width: 8px;
                content: '';
                left: 0;
                top: 7px;
                position: absolute;
                border-radius: 30px;
            }
            a {
                color: $black-color;
                display: block;

                &:hover {
                    color: $optional-color;
                    letter-spacing: 1px;
                }
            }
        }
    }
    h4 {
        font-size: 18px;
        margin-bottom: 0;
    }
    .loan-list {
        padding-left: 0;
        margin-top: 16px;
        margin-bottom: 25px;
        li {
            list-style-type: none;
            color: $paragraph-color;
            margin-bottom: 10px;
            position: relative;
            padding-left: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                left: 0;
                top: 0;
                color: $main-color;
                font-size: 20px;
            }
        }
    }
}

/*================================================
Overview Area CSS
=================================================*/
.overview-area {
    background-color: #f9fafb;
    position: relative;
    z-index: 1;
    .section-title {
        max-width: 570px;
        margin: 0 auto 45px;

        h2 {
            margin-bottom: 0;
            line-height: 1.4;
        }
    }
}

.overview-item {
    text-align: center;
    margin-bottom: 20px;
    .number {
        margin-bottom: 15px;
        span {
            display: inline-block;
            height: 100px;
            width: 100px;
            line-height: 100px;
            font-size: 20px;
            color: $black-color;
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            text-align: center;
            border-radius: 50px;
            font-weight: 500;
            font-family: $heading-font-family;
            position: relative;
            z-index: 1;
        }
        strong {
            font-size: 70px;
            color: #fad8d8;
            font-family: "Roboto", sans-serif;
            font-weight: 600;
            line-height: 0;
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
        }
    }
    h3 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 1.4;
    }
}

.overview-shape {
    .shape {
        position: absolute;
        left: 30%;
        top: 60%;
        transform: translateY(-60%);
    }
    .shape2 {
        position: absolute;
        right: 30%;
        top: 55%;
        transform: translateY(-55%);
    }
}

/*================================================
Check Area CSS
=================================================*/
.check-area {
    background-color: #f9fafb;
}

.check-content {
    margin-bottom: 30px;
    h3 {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 12px;
    }
    p {
        margin-bottom: 0;
    }
}

.check-text {
    h3 {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 12px;
    }
    p {
        margin-bottom: 0;
    }
    .list {
        padding-left: 0;
        margin-top: 12px;
        margin-bottom: 0;
        li {
            position: relative;
            margin-bottom: 12px;
            color: $paragraph-color;
            padding-left: 18px;
            text-transform: capitalize;
            list-style-type: none;
            font: {
                size: $font-size;
                weight: 500;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &::before {
                background: $optional-color;
                height: 8px;
                width: 8px;
                content: '';
                left: 0;
                top: 7px;
                position: absolute;
                border-radius: 30px;
            }
            a {
                color: $black-color;
                display: block;

                &:hover {
                    color: $optional-color;
                    letter-spacing: 1px;
                }
            }
        }
    }
}

/*================================================
Inquiry Area CSS
=================================================*/
.inquiry-content {
    margin-bottom: 30px;
    h3 {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 12px;
    }
    p {
        margin-bottom: 0;
    }
}

.inquiry-form {
    .form-group {
        label {
            color: $paragraph-color !important;
            font-family: $heading-font-family;
            font-weight: 400;
        }
        .form-control {
            background-color: transparent;
            border: 1px solid #cbcbcc;
            box-shadow: unset;
            height: 50px;
            border-radius: 0;
            color: #cbcbcc;
            font: {
                size: 16px;
            }
        }
    }
}

.inquiry-btn {
    margin-top: 20px;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 20px;
    .page-numbers {
        width: 45px;
        height: 45px;
        margin: 0 3px;
        display: inline-block;
        background-color: $white-color;
        line-height: 48px;
        color: $black-color;
        box-shadow: 0 2px 10px 0 #d8dde6;
        text-align: center;
        font: {
            size: 18px;
            weight: 700;
        }
        &.current, &:hover, &:focus {
            background: $main-color;
            color: $white-color;
            box-shadow: 0 2px 10px 0 #d8dde6;
        }
        i {
            font-size: 30px;
            position: relative;
            top: 5px;
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .post-meta {
        padding-left: 0;
        margin-top: 25px;
        margin-bottom: 16px;
        li {
            list-style-type: none;
            color: $paragraph-color;
            font-weight: 500;
            display: inline-block;
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
            i {
                color: $main-color;
                margin-right: 5px;
            }
            a {
                color: $paragraph-color;
            }
        }
    }
    .content {
        h3 {
            font-size: 30px;
            margin-bottom: 14px;
        }
        .blog-btn {
            margin-top: 20px;
        }
    }
    .desc-text {
        margin-top: 20px;
        h3 {
            font-size: 25px;
            margin-bottom: 14px;
        }
    }
    .desc-text-one {
        margin-top: 20px;
        h3 {
            font-size: 25px;
            margin-bottom: 14px;
        }
    }
    .article-share {
        margin-top: 30px;
        .social {
            padding-left: 0;
            list-style-type: none;
            background-color: #f9fafb;
            padding: 12px 25px;
            line-height: 1;
            margin: {
                bottom: 0;
            };
            li {
                display: inline-block;
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
                span {
                    display: inline-block;
                    margin-right: 2px;
                    font-weight: 600;
                    color: #333333;
                    position: relative;
                    top: -5px;
                }
                a {
                    font-size: 20px;
                    color: #333333;
                }
            }
        }
    }
}

blockquote, .blockquote {
    overflow: hidden;
    background-color: #f9fafb;
    padding: 35px !important;
    position: relative;
    z-index: 1;
    margin: {
        top: 30px;
        bottom: 30px;
    }
    p {
        color: $black-color !important;
        line-height: 1.6;
        text-align: center;
        max-width: 630px;
        margin: auto;
        font: {
            weight: 700;
            size: 16px !important;
        }
    }
    i {
        display: inline-block;
        height: 40px;
        width: 40px;
        line-height: 40px !important;
        background-color: $main-color;
        color: $white-color;
        font-size: 25px;
        text-align: center;
        position: absolute;
        margin-top: 5px;
    }
}

.comment-reply {
    margin-top: 30px;
    .comment-reply-title {
        border-bottom: 1px solid $main-color;
        padding-bottom: 10px;
        margin-bottom: 12px;
        text-transform: capitalize;
        position: relative;
        font-size: 24px;

        #cancel-comment-reply-link {
            display: inline-block;
        }
    }
    .comment-form {
        overflow: hidden;
    }
    .comment-notes {
        margin: {
            bottom: 20px;
            top: 10px;
        }
    }
    .comment-form-comment {
        margin-top: 15px;
        float: left;
        width: 100%;
    }
    label {
        display: block;
        font-weight: 400;
        color: $black-color;
        margin-bottom: 5px;
    }
    input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
        display: block;
        width: 100%;
        background-color: $white-color;
        border: 1px solid #eeeeee;
        padding: 0.625em 0.7375em;
        outline: 0;
        transition: $transition;

        &:focus {
            border-color: $optional-color;
        }
    }
    .comment-form-author {
        float: left;
        width: 50%;
        padding-right: 10px;
        margin-bottom: 20px;
    }
    .comment-form-email {
        float: left;
        width: 50%;
        padding-left: 12px;
        margin-bottom: 20px;
    }
    .comment-form-url {
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }
    .comment-form-cookies-consent {
        width: 100%;
        float: left;
        position: relative;
        padding-left: 20px;
        margin-bottom: 20px;

        input {
            position: absolute;
            left: 0;
            top: 4px;
        }
        label {
            display: inline-block;
            margin: 0;
            color: $paragraph-color;
            font-weight: normal;
            position: relative;
            top: -2px;
        }
    }
    .form-submit {
        float: left;
        width: 100%;
        input {
            background: $optional-color;
            border: none;
            color: $white-color;
            padding: 12px 30px;
            display: inline-block;
            cursor: pointer;
            outline: 0;
            border-radius: 2px;
            transition: $transition;
            font: {
                weight: 500;
                size: $font-size;
            }
            &:hover, &:focus {
                color: $white-color;
                background-color: $black-color;
            }
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    background-color: $black-color;

    &.bg-top {
        .single-footer-widget {
            margin-top: 80px;
        }
    }
}
.single-footer-widget {
    margin-bottom: 25px;

    .logo {
        img {
            margin-bottom: 25px;
        }
    }
    p {
        color: $white-color;
        margin-bottom: 0;
    }
    .social {
        padding-left: 0;
        margin-top: 20px;
        margin-bottom: 0;

        li {
            list-style-type: none;
            display: inline-block;
            color: $white-color;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
            a {
                color: $white-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
    h3 {
        font-size: 20px;
        color: $white-color;
        margin-bottom: 22px;
        padding-bottom: 20px;
        border-bottom: 1px solid #333535;
    }
    .quick-links {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 15px;
            color: $white-color;
            transition: $transition;

            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $white-color;
                transition: $transition;

                &:hover {
                    color: $main-color;
                    letter-spacing: 1px;
                }
            }
        }
    }
    .info-contact {
        position: relative;
        padding-left: 38px;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
        i {
            position: absolute;
            left: 0;
            font-size: 20px;
            color: $main-color;
            top: 5px;
        }
        span {
            color: $white-color;
            margin-bottom: 6px;
            display: block;
            font-size: 15.5px;

            &:last-child {
                margin-bottom: 0;
            }
        }
        a {
            color: $white-color;
            display: inline-block;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*================================================
Copy Right Area CSS
=================================================*/
.copy-right-area {
    background-color: $black-color;
    border-top: 1px solid #292929;
    padding: {
        top: 30px;
        bottom: 30px;
    };
}
.copy-right-content {
    text-align: center;

    p {
        color: #b3b3b3;

        a {
            display: inline-block;
            font-weight: 600;
            color: $white-color;

            &:hover {
                color: $main-color;
            }
        }
    }
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: -100px;
    right: 20px;
    color: $white-color;
    background-color: $black-color;
    z-index: 4;
    width: 45px;
    text-align: center;
    height: 45px;
    opacity: 0;
    visibility: hidden;
    border-radius: 50%;
    font-size: 22px;
    transition: .2s;
    overflow: hidden;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    i {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        font-size: 30px;
        margin: {
            left: auto;
            right: auto;
        };
    }
    &.active {
        opacity: 1;
        visibility: visible;
        bottom: 20px;
    }
    &:hover {
        background-color: $main-color;
        color: $white-color;
        transition: $transition;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);
    }
}
