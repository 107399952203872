@media only screen and (max-width: 767px) {
    .pb-100 {
        padding-bottom: 50px;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pb-70 {
        padding-bottom: 20px;
    }
    .pt-100 {
        padding-top: 50px;
    }
    p {
        font-size: 15px;
    }
    .navbar-area {
        .others-options {
            .option-item {
                .info {
                    display: none;
                }
                .search-btn {
                    display: none;
                }
                .close-btn {
                    display: none;
                }
                .default-btn {
                    position: absolute;
                    top: -3px;
                    right: 50px;
                    font-size: 12px;
                    padding: 8px 15px;
                }
            }
        }
    }
    .main-responsive-nav {
        .logo {
            max-width: 30%;
            width: unset;
        }
    }
    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 1px 0 0 0;

                span {
                    display: block;
                    background: #000;
                    height: 4px;
                    margin-top: -5px;
                    border-radius: 3px;
                    position: relative;
                    top: 8px;
                }
            }
        }
        .mean-bar {
            background-color: unset;
            border-bottom: none;
        }
        .mean-nav {
            margin-top: 40px;
        }
    }
    .banner-social-buttons {
        position: relative;
        right: 0;
        transform: unset;
    }
    .main-banner-content {
        max-width: 100%;

        span {
            font-size: 16px;
        }
        h1 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        .banner-btn {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        p {
            font-size: 15px;
        }
    }
    .main-banner-area.bg-two .main-banner-content .banner-btn {
        margin-top: 20px;
    }
    .main-banner-area {
        padding-top: 50px;
    }
    .approvals-area {
        margin-top: 55px;

        &.bg-color {
            .approvals-content {
                border-bottom: none;
            }
        }
    }
    .main-banner-area {
        .container-fluid {
            max-width: 100%;
        }
    }
    .main-banner-area.bg-two {
        .banner-social-buttons {
            position: relative;
            left: 1px;
            top: 0;
            display: block;
            bottom: 0;
            transform: unset;
            margin-top: 10px;

            ul {
                li {
                    font-size: 14px;
                }
            }
        }
    }
    .main-banner-area.bg-two .banner-social-buttons ul li span::before {
        right: -24px;
        top: 9px;
        width: 15px;
    }
    .banner-social-buttons ul li {
        margin-right: 10px;
    }
    .approvals-content {
        border-bottom: 1px solid #9ca2a0;
        &::before {
            display: none;
        }
        span {
            font-size: 16px;
        }
        p {
            font-size: 14px;
        }
    }
    .about-image {
        padding-right: 0;
        padding-bottom: 10px;

        img {
            width: 100%;
            &:nth-child(2) {
                position: relative;
                right: 0;
                bottom: -12px;
            }
        }
    }
    .experience {
        position: relative;
        text-align: center;
        padding: 25px;
        border-left: none;
        h4 {
            position: relative;
            font-size: 45px;
            left: 0;
            top: 0;
        }
        p {
            font-size: 16px;
            padding-left: 0;
        }
    }
    .about-content {
        margin-top: 50px;
        h3 {
            font-size: 25px;
            margin-bottom: 14px;
        }    
    }
    .section-title {
        margin-bottom: 30px;
        h2 {
            font-size: 25px;
        }
        p {
            font-size: 15px;
        }
    }
    .process-item {
        .icon {
            i {
                height: 70px;
                width: 70px;
                line-height: 70px;
                font-size: 40px;
            }
        }
    }
    .company-image {
        height: 400px;
    }
    .company-content {
        max-width: 100%;
        padding-top: 30px;
        padding-bottom: 50px;
        h3 {
            font-size: 25px;
            margin-bottom: 30px;
        }
        p {
            font-size: 15px;
        }
        .company-text {
            h4 {
                font-size: 20px;
            }
        }
    }
    .company-area {
        .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .deserve-item {
        h3 {
            font-size: 25px;
        }
        .deserve-content {
            h4 {
                font-size: 20px;
            }
        }
        .deserve-btn {
            margin-top: 25px;
            margin-bottom: 30px;
        }
    }
    .rate-form {
        padding: 30px 15px;

        .rate-content {
            margin-bottom: 30px;
            h3 {
                font-size: 20px;
            }
        }
    }
    .projects-slider {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0;
                    &.owl-next {
                        right: 0;
                    }
                }
            }
        }
    }
    .clients-item {
        padding: 25px;
        margin-bottom: 30px;
    }
    .clients-item {
        .icon {
            i {
                font-size: 35px;
            }
        }
    }
    .blog-item {
        .content {
            h3 {
                font-size: 20px;
            }   
        } 
    }
    .main-banner-area {
        &.bg-two {
            height: 100%;
            padding-top: 50px;
            padding-bottom: 50px;

            .main-banner-content {
                max-width: 100%;
                .banner-btn {
                    margin-bottom: 0;
                }
            }
        }
    }
    .about-wrap {
        text-align: center;
        .about-shape {
            right: 0;
            left: 0;
            margin: auto;
        }
    }
    .choose-title {
        text-align: center;
        h2 {
            font-size: 25px;
        }
        p {
            max-width: 100%;
        }
    }
    .choose-image {
        text-align: center;
        .video-btn {
            left: 0;
            right: 0;
        }
    }
    .solution-item {
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        .content {
            h3 {
                font-size: 30px;
            }
            &::before {
                display: none;
            }
        }
        .solution-info {
            padding-left: 0;
            text-align: center;
            margin-top: 30px;
            i {
                position: relative;
                left: 0;
                top: 0;
            }
            h3 {
                font-size: 25px;
                margin-top: 10px;
            }
        }
    }
    .company-wrap {
        margin-top: 30px;
        text-align: center;
    }
    .services-tab-image {
        margin-bottom: 30px;
        text-align: center;
    }
    .clients-slider {
        &.owl-theme {
            .owl-dots {
                display: none;
            }
        }
        .clients-item {
            .clients-info-text {
                text-align: center;
                max-width: 100%;
                p {
                    font-size: 18px;
                }
            }
            .clients-image {
                margin-top: 30px;
            }
        }
    }
    .services-list-tab {
        .tab_content {
            .tabs_item {
                .list{
                    h3 {
                        font-size: 22px;
                    }
                }
                .services-tab-content {
                    h3 {
                        font-size: 22px;
                    }
                }
            }
        }
    }
    .main-banner-area {
        &.bg-three {
            height: 100%;
            padding-top: 50px;
            .main-banner-content {
                h1 {
                    font-size: 25px;
                }
                p {
                    max-width: 100%;
                    font-size: 16px;
                }
                .banner-btn {
                    margin-top: 20px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    .approvals-item {
        padding-left: 25px;
        padding-right: 25px;
    }
    .single-fun-fact {
        border-right: none;
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 16px;
        }
    }
    .tabs-list {
        margin-bottom: 25px;
        li {
            flex: unset;
            max-width: unset;
            padding: 10px;
            width: 100%;

            i {
                font-size: 40px;
            }
            span {
                font-size: 16px;
            }
        }
    }
    .services-tab-image1 {
        margin-top: 30px;
        text-align: center;
    }
    .value-content {
        margin-top: 30px;
        h3 {
            font-size: 25px;
        }
    }
    .value-features {
        li {
            flex: unset;
            max-width: unset;
        }
    }
    .clients-area {
        &.bg-color {
            border-top: 1px solid #eeeeee;
            border-bottom: 1px solid #eeeeee;

            .clients-slider {
                .clients-item {
                    .clients-image {
                        margin-top: 0;
                        margin-bottom: 30px;
                        .icon-1 {
                            bottom: 16px;
                            left: 0;
                            right: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .process-item-two {
        margin: 0 auto 30px;
    }
    .page-title-area {
        height: 265px;
    }
    .page-title-content {
        h2 {
            font-size: 30px;
        }
    }
    .solution-area {
        &.bg-top {
            margin-bottom: 0;
            padding-bottom: 50px;
        }
    }
    .footer-area {
        &.bg-top {
            .single-footer-widget {
                margin-top: 0;
            }
        }
    }
    
    .faq-list-tab {
        .tabs-list {
            li {
                flex: unset;
                max-width: unset;
                width: 100%;
            }
        }
    }
    .faq-contact {
        margin-top: 50px;
        .section-title {
            h2 {
                font-size: 25px;
            }
        }
    }
    .table-responsive {
        overflow-x: auto;
    }
    .calculator-form {
        padding: 40px 20px;
        .calculator-btn {
            .btn-one {
                padding: 10px 25px;
                margin-right: 0;
            }
            .btn-two {
                padding: 10px 20px;
                margin-top: 25px;
            }
        }
    }
    .projects-details-content {
        .projects-details-info {
            .single-info-box {
                flex: unset;
                max-width: unset;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .sign-in-form {
        padding: 20px;
        .forgot-password {
            margin-top: 0;
            text-align: start;
        }
    }
    .signup-form {
        padding: 20px;
    }
    .terms-of-condition-sidebar {
        margin-top: 30px;
    }
    .contact-side-box {
        margin-top: 30px;
    }
    .funding-image {
        img {
            height: auto;
            width: 100%;
        }
    }
    .funding-content {
        margin-top: 30px;
        h3 {
            font-size: 26px;
        }
    }
    .works-shape {
        .shape {
            display: none;
        }
        .shape2 {
            display: none;
        }
    }
    .funding-form-area {
        overflow: hidden;

        .container {
            max-width: 100%;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .loan-content {
        margin-top: 30px;
        h3 {
            font-size: 30px;
        }
    }
    .overview-shape {
        .shape {
            display: none;
        }
        .shape2 {
            display: none;
        }
    }
    .check-content {
        margin-top: 30px;
        h3 {
            font-size: 26px;
            font-weight: 500;
        }
    }
    .check-text {
        h3 {
            font-size: 26px;
        }
    }
    .inquiry-btn {
        margin-bottom: 30px;
    }
    .loan-image {
        text-align: center;
        .loan-shape {
            right: 0;
            left: 0;
            margin: auto;
        }
    }
    .default-image {
        text-align: center;
    }
    .check-image {
        text-align: center;
    }

    .services-details-desc {
        margin-top: 30px;
        .content {
            h3 {
                font-size: 26px;
            }
        }
    }
    .services-details-features {
        padding: 50px 20px;
        .content {
            margin-bottom: 0;
        }
        .features-item {
            margin-top: 30px;
        }
    }
    .services-details-others {
        h3 {
            font-size: 25px;
        }
    }
    .widget-area {
        .widget {
            .widget-title {
                font-size: 20px;
            }
        }
    }
    .single-blog {
        .post-meta {
            li {
                margin-right: 8px;
                font-size: 13px;
            }
        }
        .content {
            h3 {
                font-size: 22px;
            }
        }
    }
    .pagination-area {
        margin-top: 10px;
        margin-bottom: 45px;
    }
    .blog-details-desc {
        .post-meta {
            li {
                margin-right: 8px;
                font-size: 13px;
            }
        }
        .content {
            h3 {
                font-size: 24px;
            }
        }
        .desc-text {
            h3 {
                font-size: 20px;
            }
        }
        .article-share {
            .social {
                padding: 12px 20px;
            }
        }
    }
    blockquote, .blockquote {
        padding: 25px !important;
        text-align: center;
    }
    blockquote i, .blockquote i {
        position: relative;
        margin-top: 0;
        margin-bottom: 10px;
    }
    .comment-reply {
        .form-submit {
            margin-bottom: 40px;
        }
    }
    .best-services-slider {
        .tabs-item {
            span {
                font-size: 15px;
            }
        }
    }
    .blog-item {
        .image {
            img {
                width: 100%;
            }
        }
    }
    .team-item {
        .image {
            img {
                width: 100%;
            }
        }
    }
    .projects-slider {
        .projects-item {
            .content {
                text-align: center;
            }
        }
    }
}

@media only screen and (min-width: 414px) {
    .navbar-area {
        .others-options {
            .option-item {
                .default-btn {
                    top: 2px;
                }
            }
        }
    }
    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 6px 0 0 0;
            }
        }
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

    .loan-area .container {
        max-width: 540px;
    }
    .navbar-area {
        .others-options {
            .option-item {
                .info {
                    display: none;
                }
                .search-btn {
                    display: block;
                    position: absolute;
                    top: 8px;
                    right: 150px;
                }
                .close-btn {
                    display: none;
                    position: absolute;
                    top: 8px;
                    right: 150px;

                    &.active {
                        display: block;
                    }
                }
                .default-btn {
                    position: absolute;
                    top: 2px;
                    right: 50px;
                    font-size: 12px;
                    padding: 8px 15px;
                }
            }
        }
    }
    .main-responsive-nav {
        .logo {
            max-width: 22%;
            width: unset;
        }
    }
    .search-overlay {
        &.search-popup {
            left: 0;
            right: 0;
            margin: -4px auto 0;
        }
    }
    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 6px 0 0 0;
            }
        }
    }

    .main-banner-area {
        .container-fluid {
            max-width: 540px;
        }
    }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {

    .loan-area .container {
        max-width: 720px;
    }
    .pb-100 {
        padding-bottom: 70px;
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pb-70 {
        padding-bottom: 40px;
    }
    .pt-100 {
        padding-top: 70px;
    }
    .navbar-area {
        .others-options {
            .option-item {
                .info {
                    display: none;
                }
                .search-btn {
                    display: block;
                    position: absolute;
                    top: 8px;
                    right: 150px;
                }
                .close-btn {
                    display: none;
                    position: absolute;
                    top: 8px;
                    right: 150px;

                    &.active {
                        display: block;
                    }
                }
                .default-btn {
                    position: absolute;
                    top: 2px;
                    right: 50px;
                    font-size: 12px;
                    padding: 8px 15px;
                }
            }
        }
    }
    .main-responsive-nav {
        .logo {
            max-width: 17%;
            width: unset;
        }
    }
    .search-overlay {
        &.search-popup {
            left: 0;
            right: 0;
            margin: -4px auto 0;
        }
    }
    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 6px 0 0 0;
                span {
                    display: block;
                    background: #000;
                    height: 4px;
                    margin-top: -5px;
                    border-radius: 3px;
                    position: relative;
                    top: 8px;
                }
            }
        }
        .mean-bar {
            background-color: unset;
            border-bottom: none;
        }
        .mean-nav {
            margin-top: 52px;
        }
    }
    .banner-social-buttons {
        position: relative;
        right: 0;
        transform: unset;
    }
    .main-banner-content {
        max-width: 100%;
        h1 {
            font-size: 50px;
        }
        .banner-btn {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    .main-banner-area {
        .container-fluid {
            max-width: 720px;
        }
    }
    .main-banner-area {
        padding-top: 70px;
    }
    .approvals-area {
        margin-top: 75px;

        &.bg-color {
            .approvals-content {
                border-bottom: none;
            }
        }
    }
    .approvals-content {    
        border-bottom: 1px solid #9ca2a0;
        &::before {
            display: none;
        }
    }
    .about-content {
        margin-top: 60px;
        h3 {
            margin-bottom: 14px;
        }    
    }
    .about-image {
        img {
            &:nth-child(2) {
                right: 0;
            }
        }
    }
    .section-title {
        margin-bottom: 30px;
    }
    .process-item {
        .icon {
            i {
                height: 70px;
                width: 70px;
                line-height: 70px;
                font-size: 40px;
            }
        }
    }
    .company-image {
        height: 400px;
    }
    .company-content {
        max-width: 100%;
        padding-top: 30px;
        padding-bottom: 50px;
        h3 {
            font-size: 25px;
            margin-bottom: 30px;
        }
        p {
            font-size: 15px;
        }
        .company-text {
            h4 {
                font-size: 20px;
            }
        }
    }
    .company-area {
        .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .deserve-item {
        h3 {
            font-size: 25px;
        }
        .deserve-content {
            h4 {
                font-size: 20px;
            }
        }
        .deserve-btn {
            margin-top: 25px;
            margin-bottom: 30px;
        }
    }
    .rate-form {
        padding: 30px 15px;
    }
    .projects-slider {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0;
                    &.owl-next {
                        right: 0;
                    }
                }
            }
        }
    }
    .clients-item {
        padding: 25px;
        margin-bottom: 30px;
    }
    .clients-item {
        .icon {
            i {
                font-size: 35px;
            }
        }
    }
    .blog-item {
        .content {
            h3 {
                font-size: 20px;
            }   
        } 
    }
    .main-banner-area {
        &.bg-two {
            height: 100%;
            padding-top: 70px;
            padding-bottom: 70px;

            .main-banner-content {
                max-width: 530px;

                .banner-btn {
                    margin-bottom: 0;
                    margin-top: 20px;
                }
                p {
                    font-size: 16px;
                }
                span {
                    font-size: 16px;
                }
            }
            .banner-social-buttons {
                position: relative;
                left: 0;
                right: auto;
                top: unset;
                bottom: 0;
                transform: unset;
                margin-top: 15px;
            }
        }
    }
    .about-wrap {
        text-align: center;
        .about-shape {
            right: 0;
            left: 0;
            margin: auto;
        }
    }
    .choose-title {
        text-align: center;
        h2 {
            font-size: 25px;
        }
        p {
            max-width: 100%;
        }
    }
    .choose-image {
        text-align: center;
        margin-bottom: 30px;
        .video-btn {
            left: 0;
            right: 0;
        }
    }
    .solution-item {
        padding-top: 25px;
        padding-bottom: 25px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
        .content {
            h3 {
                font-size: 30px;
            }
            &::before {
                display: none;
            }
        }
        .solution-info {
            padding-left: 0;
            text-align: center;
            margin-top: 30px;
            i {
                position: relative;
                left: 0;
                top: 0;
            }
            h3 {
                font-size: 25px;
                margin-top: 10px;
            }
        }
    }
    .company-wrap {
        margin-top: 30px;
        text-align: center;
    }
    .services-tab-image {
        margin-bottom: 30px;
        text-align: center;
    }
    .clients-slider {
        .clients-item {
            .clients-info-text {
                text-align: center;
                max-width: 100%;
                p {
                    font-size: 18px;
                }
            }
            .clients-image {
                margin-top: 30px;
                img {
                    margin: auto;
                }
                .icon-1 {
                    left: 0;
                    right: 0;
                    text-align: center;
                }
            }
        }
        &.owl-theme {
            .owl-dots {
                right: 0;
            }
        }
    }
    .services-list-tab {
        .tab_content {
            .tabs_item {
                .list{
                    h3 {
                        font-size: 22px;
                    }
                }
                .services-tab-content {
                    h3 {
                        font-size: 22px;
                    }
                }
            }
        }
    }
    .main-banner-area {
        &.bg-three {
            height: 100%;
            padding-top: 70px;
            .main-banner-content {
                p {
                    max-width: 100%;
                    font-size: 16px;
                }
                .banner-btn {
                    margin-top: 20px;
                    margin-bottom: 30px;
                }
            }
        }
    }
    .approvals-item {
        padding-left: 25px;
        padding-right: 25px;
    }
    .single-fun-fact {
        border-right: none;
        h3 {
            font-size: 30px;
        }
        p {
            font-size: 16px;
        }
    }
    .tabs-list {
        margin-bottom: 25px;
        li {
            flex: unset;
            max-width: unset;
            padding: 10px;
            margin-right: 10px;
            i {
                font-size: 40px;
            }
            span {
                font-size: 13px;
            }
        }
    }
    .services-tab-image1 {
        margin-top: 30px;
        text-align: center;
    }
    .value-content {
        margin-top: 30px;
        h3 {
            font-size: 25px;
        }
    }
    .value-features {
        li {
            flex: unset;
            max-width: unset;
        }
    }
    .clients-area {
        &.bg-color {
            border-top: 1px solid #eeeeee;
            border-bottom: 1px solid #eeeeee;

            .clients-slider {
                .clients-item {
                    .clients-image {
                        margin-top: 0;
                        margin-bottom: 30px;
                        .icon-1 {
                            bottom: 16px;
                            left: 0;
                            right: 0;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .process-item-two {
        margin: 0 auto 30px;
    }
    .page-title-area {
        height: 265px;
    }
    .page-title-content {
        h2 {
            font-size: 30px;
        }
    }
    .solution-area {
        &.bg-top {
            margin-bottom: 0;
            padding-bottom: 50px;
        }
    }
    .footer-area {
        &.bg-top {
            .single-footer-widget {
                margin-top: 0;
            }
        }
    }
    .faq-list-tab {
        .tabs-list {
            li {
                flex: unset;
                max-width: unset;
                width: 100%;
            }
        }
    }
    .faq-contact {
        margin-top: 50px;
        .section-title {
            h2 {
                font-size: 25px;
            }
        }
    }
    .projects-details-content {
        .projects-details-info {
            .single-info-box {
                flex: unset;
                max-width: unset;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .terms-of-condition-sidebar {
        margin-top: 30px;
    }
    .contact-side-box {
        margin-top: 30px;
    }
    .sign-in-form {
        padding: 20px;
        .forgot-password {
            margin-top: 0;
            text-align: start;
        }
    }
    .signup-form {
        padding: 20px;
    }
    .terms-of-condition-sidebar {
        margin-top: 30px;
    }
    .contact-side-box {
        margin-top: 30px;
    }
    .funding-image {
        img {
            height: auto;
            width: 100%;
        }
    }
    .funding-content {
        margin-top: 30px;
        h3 {
            font-size: 26px;
        }
    }
    .works-shape {
        .shape {
            display: none;
        }
        .shape2 {
            display: none;
        }
    }
    .funding-form-area {
        overflow: hidden;

        .container {
            max-width: 100%;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .loan-content {
        margin-top: 30px;
        h3 {
            font-size: 30px;
        }
    }
    .overview-shape {
        .shape {
            display: none;
        }
        .shape2 {
            display: none;
        }
    }
    .check-content {
        margin-top: 30px;
        h3 {
            font-size: 26px;
            font-weight: 500;
        }
    }
    .check-text {
        h3 {
            font-size: 26px;
        }
    }
    .inquiry-btn {
        margin-bottom: 30px;
    }
    .loan-image {
        text-align: center;
        .loan-shape {
            right: 0;
            left: 0;
            margin: auto;
        }
    }
    .default-image {
        text-align: center;
    }
    .check-image {
        text-align: center;
    }
    .services-details-desc {
        margin-top: 30px;
        .content {
            h3 {
                font-size: 26px;
            }
        }
    }
    .services-details-features {
        padding: 50px 20px;
        .content {
            margin-bottom: 0;
        }
        .features-item {
            margin-top: 30px;
        }
    }
    .services-details-others {
        h3 {
            font-size: 25px;
        }
    }
    .widget-area {
        .widget {
            .widget-title {
                font-size: 20px;
            }
        }
    }
    .single-blog {
        .post-meta {
            li {
                margin-right: 8px;
                font-size: 13px;
            }
        }
        .content {
            h3 {
                font-size: 22px;
            }
        }
    }
    .pagination-area {
        margin-top: 10px;
        margin-bottom: 45px;
    }
    .blog-details-desc {
        .post-meta {
            li {
                margin-right: 8px;
                font-size: 13px;
            }
        }
        .content {
            h3 {
                font-size: 24px;
            }
        }
        .desc-text {
            h3 {
                font-size: 20px;
            }
        }
        .article-share {
            .social {
                padding: 12px 20px;
            }
        }
    }
    blockquote, .blockquote {
        padding: 25px !important;
        text-align: center;
    }
    blockquote i, .blockquote i {
        position: relative;
        margin-top: 0;
        margin-bottom: 10px;
    }
    .comment-reply {
        .form-submit {
            margin-bottom: 40px;
        }
    }
    .blog-item {
        .image {
            img {
                width: 100%;
            }
        }
    }
    .team-item {
        .image {
            img {
                width: 100%;
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .navbar-area {
        .others-options {
            .option-item {
                .info {
                    display: none;
                }
                .search-btn {
                    display: block;
                    position: absolute;
                    top: 16px;
                    right: 155px;
                }
                .close-btn {
                    display: none;
                    position: absolute;
                    top: 15px;
                    right: 155px;

                    &.active {
                        display: block;
                    }
                }
                .default-btn {
                    position: absolute;
                    top: 8px;
                    right: 50px;
                    font-size: 12px;
                    padding: 8px 15px;
                }
            }
        }
    }
    .search-overlay.search-popup {
        margin-top: -12px;
    }
    .loan-area .container {
        max-width: 960px;
    }
    .mean-container {
        a {
            &.meanmenu-reveal {
                padding: 0;
                margin-top: -8px;

                span {
                    display: block;
                    background: #000000;
                    height: 4px;
                    margin-top: -5px;
                    border-radius: 3px;
                    position: relative;
                    top: 8px;
                }
            }
        }
        .mean-bar {
            background-color: unset;
            border-bottom: none;
        }
        .mean-nav {
            margin-top: 52px;
        }
    }

    .main-banner-area.bg-two {
        height: 765px;
    }
    .main-banner-area.bg-two .main-banner-content {
        max-width: 525px;
    }
    .main-banner-area .container-fluid {
        max-width: 960px;
    }
    .main-banner-content h1 {
        font-size: 55px;
    }
    .main-banner-content p {
        font-size: 16px;
    }

    .about-content {
        h3 {
            font-size: 30px;
            font-weight: 500;
        }
    }
    .deserve-item {
        h3 {
            font-size: 30px;
        }
    }
    .rate-item {
        padding: 50px 40px;
        .rate-dollar {
            li {
                margin-right: 85px;
            }
        }
        .rate-month {
            li {
                margin-right: 75px;
            }
        }
    }
    .projects-slider {
        &.owl-theme {
            .owl-nav {
                [class*=owl-] {
                    left: 0;
                    &.owl-next {
                        right: 0;
                    }
                }
            }
        }
    }
    .solution-item {
        .content {
            h3 {
                font-size: 30px;
            }
        }
        .solution-info {
            i {
                height: 60px;
                width: 60px;
                line-height: 60px;
            }
            h3 {
                font-size: 24px;
            }
        }
    }
    .clients-slider {
        &.owl-theme {
            .owl-dots {
                right: 10px;
            }
        }
    }
    .process-item-two {
        height: 245px;
        width: 220px;
        .text {
            top: 30px;
            .icon {
                i {
                    font-size: 40px;
                }
            }
            h3 {
                font-size: 20px;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            p {
                font-size: 12px;
            }
        }
    }
    .tabs-list {
        li {
            flex: 1 0 14.666667%;
            i {
                font-size: 45px;
            }
            span {
                font-size: 16px;
            }
        }
    }
    .value-features {
        li {
            font-size: 16px;
        }
    }
    .clients-area {
        &.bg-color {
            border-top: 1px solid #eeeeee;
            border-bottom: 1px solid #eeeeee;
        }
    }
    .projects-details-content {
        .projects-details-info {
            .single-info-box {
                flex: unset;
                max-width: unset;
            }
        }
    }
    .main-navbar {
        .navbar {
            .navbar-nav {
                .nav-item {
                    .dropdown-menu {
                        li {
                            .dropdown-menu {
                                right: -250px;
                                left: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    .funding-content {
        h3 {
            font-size: 32px;
        }
        .funding-list {
            li {
                font-size: 15px;
            }
        }
    }
    .works-shape {
        .shape {
            left: 24%;
        }
        .shape2 {
            right: 24%;
        }
    }
    .overview-shape {
        .shape {
            left: 24%;
        }
        .shape2 {
            right: 24%;
        }
    }
    .approvals-item {
        .approvals-content {
            &::before {
                right: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 2500px) {
    .container {
        max-width: 1250px;
    }
}

@media only screen and (min-width: 1800px) {
    .rate-item {
        .rate-month{
            &::before {
                height: 6px;
            }
        }
    }

    .main-banner-area {
        &.bg-two {
            .banner-social-buttons {
                left: 235px
            }
        }
    }

    .works-shape {
        .shape {
            left: 35%;
        }
        .shape2 {
            right: 35%;
        }
    }

    .overview-shape {
        .shape {
            left: 34%;
        }
        .shape2 {
            right: 34%;
        }
    }
}